import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import '../ContactForm/contactForm.css';
import axios from 'axios';
import { BACKEND_URL } from '../../config/const';
const ContactForm = () => {
const [t] = useTranslation(["translation"]);
const [lastname, setLastname] = useState('');
const [firstname, setFirstname] = useState('');

  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('')
  const [authSuccess, setAuthSuccess] = useState(null); 
  const [formattedPhone, setFormattedPhone] = useState('');
  const [errorPhone, setErrorPhone] = useState(false);
  const [checkPhone, setCheckPhone] = useState(false);
  const [errorFirstName, setErrorFirstname] = useState(false);
  const [errorLastname, setErrorLastname] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [checkMessage, setCheckMessage] = useState(false);
  const [checkFirstname, setCheckFirstname] = useState(false);
  const [checkLastname, setCheckLastname] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const handleSubmitContactUs = async (e) => {
    e.preventDefault();
      if(!checkLastname){
        console.log("aa")
      setErrorLastname(t('champLastnameObligatoire'))
     }if(!checkFirstname){
      setErrorFirstname(t('champFirstnameObligatoire'))
     }
     if(!checkEmail){
      setErrorEmail(t('champEmailObligatoire'))
     }
     if(!checkPhone){
      setErrorPhone(t('champPhoneObligatoire'))
     }
     if(!checkMessage){
      setErrorMessage(t('champMessageObligatoire'))
     }
    // Vérification des champs requis ici
    if (checkLastname && checkFirstname && checkEmail && checkPhone && checkMessage) {
      const response = await axios.post(BACKEND_URL+'/api/contactUs',{ lastname,firstname, email,message ,phone,lang: localStorage.getItem('i18nextLng')});

      setAuthSuccess(true) 
      setTimeout(() => {
        
        setFirstname('')
        setLastname('')
        setPhone('')
        setEmail('')
        setMessage('')
        setAuthSuccess(null)

      }, 1500);
      
    } else {
      setAuthSuccess(false) 
      setTimeout(() => {
        
        setFirstname('')
        setLastname('')
        setPhone('')
        setEmail('')
        setMessage('')
        setAuthSuccess(null)

      }, 1500);
    }
  };
  const handleChangePhone = (event) => {
   
    const inputValue = event.target.value;
    // Supprimer tous les caractères non numériques du champ
    const numericValue = inputValue.replace(/\D/g, '');
    if (numericValue === '' | numericValue === null | numericValue === 'undefined') {
      setErrorPhone(t('champPhoneObligatoire'));
      setCheckPhone(false);
    } else {
      setCheckPhone(true);
      setErrorPhone('');
    }
    let formattedValue = '';
    for (let i = 0; i < numericValue.length && i < 10; i++) {
      if (i === 0) formattedValue += '(';
      else if (i === 3) formattedValue += ') ';
      else if (i === 6) formattedValue += '-';
      formattedValue += numericValue[i];
    }

    setPhone(numericValue);
    setFormattedPhone(formattedValue);
  };
  const handleChangeFirstname = (event) => {
    setFirstname(event.target.value);
    if (
      event.target.value === ''
      || event.target.value === null
      || event.target.value === 'undefined'
    ) {
      setErrorFirstname(t('champFirstnameObligatoire'));
      setCheckFirstname(false);
    } else {
      setCheckFirstname(true);
      setErrorFirstname('');
    }
  };
  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
    if (
      event.target.value === ''
      || event.target.value === null
      || event.target.value === 'undefined'
    ) {
      setErrorMessage(t('champMessageObligatoire'));
      setCheckMessage(false);
    } else {
      setCheckMessage(true);
      setErrorMessage('');
    }
  };
  const handleChangeLastname = (event) => {
    setLastname(event.target.value);
    if (
      event.target.value === ''
      || event.target.value === null
      || event.target.value === 'undefined'
    ) {
      setErrorLastname(t('champLastnameObligatoire'));
      setCheckLastname(false);
    } else {
      setCheckLastname(true);
      setErrorLastname('');
    }
  };
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    if (
      event.target.value === ''
      || event.target.value === null
      || event.target.value === 'undefined'
    ) {
      setErrorEmail(t('champEmailObligatoire'));
      setCheckEmail(false);
    } else if (!isValidEmail(event.target.value)) {
      setErrorEmail(t('EmailIsInvalid'));
      setCheckEmail(false);
    } else {
      setCheckEmail(true);
      setErrorEmail('');
    }
  };
  return (

    <div className="form-container">
        
        
    <form onSubmit={handleSubmitContactUs}>
    <center>
          <img src={localStorage.getItem('i18nextLng')==="en"?"/images/bceEN.png":"/images/bceFR.png"} className="card-img-top logoContact" alt="..."/> 
        </center>
        <div className="form-group">
        <label>{t('contactEmail')}</label>
        </div>

      <div className="form-group">
        <label htmlFor="firstname">{t('firstname')}</label>
        <input
          type="text"
          id="firstname"
          name={t('firstname')}
          value={firstname}
          onChange={handleChangeFirstname}
          
        />
  <p style={{ color: 'red', fontSize: '0.7em' }}>
                        {' '}
                        {errorFirstName}
        </p>
      </div>
      <div className="form-group">
        <label htmlFor="lastname">{t('lastname')}</label>
        <input
          type="text"
          id="lastname"
          name={t('lastname')}
          value={lastname}
          onChange={handleChangeLastname}
          
        />
        <p style={{ color: 'red', fontSize: '0.7em' }}>
                        {' '}
                        {errorLastname}
        </p>
      </div>
      <div className="form-group">
        <label htmlFor="email">{t('email')}</label>
        <input
          type="email"
          id="email"
          name={t('email')}
          value={email}
          onChange={handleChangeEmail}
          
        />
        <p style={{ color: 'red', fontSize: '0.7em' }}>
                        {' '}
                        {errorEmail}
        </p>
      </div>
      <div className="form-group">
        <label htmlFor="phone">{t('phone')}</label>
        <input
          type="text"
          id="phone"
          name={t('phone')}
          value={formattedPhone}
          onChange={handleChangePhone}
          
        />
        <p style={{ color: 'red', fontSize: '0.7em' }}>
                        {' '}
                        {errorPhone}
        </p>
      </div>
      <div className="form-group">
        <label htmlFor="message">{t('message')}</label>
        <textarea
          id="message"
          name={t('message')}
          value={message}
          onChange={handleChangeMessage}
          
        ></textarea>
         <p style={{ color: 'red', fontSize: '0.7em' }}>
                        {' '}
                        {errorMessage}
        </p>
      </div>
      <div className='form-group'>
      {authSuccess===true && (
                <div className="alert alert-success mt-3 mb-0">{t("emailsent")}</div>
              )}
      {authSuccess===false && (
                <div className="alert alert-danger mt-3 mb-0">{t("errorEmailsent")}</div>
              )}
      </div>
      <button type="submit">{t('send')}</button>
    </form>
    </div>
   
  );
};

export default ContactForm;