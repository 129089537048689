import React, { useState , useEffect } from 'react';
import './changeForgetPass.css'; // Assurez-vous de remplacer le chemin par le bon emplacement de votre fichier CSS
import StrengthMeter from '../ForgetPass/StrengthMeter';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { BACKEND_URL } from "../../config/const";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import i18n from "../../_i18n/i18n";

const Changeforgetpass=()=> {
    const { t } = useTranslation();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [checkConfirm, setCheckConfirm] = useState(false);
  const [successAdd, setSuccessAdd] = useState(null);
  const { username } = useParams();
  const { lang } = useParams();
  const navigate = useNavigate();
  const [pwdInput, initValue] = useState({
    password: '',
  });
  const [isStrong, initRobustPassword] = useState(null);
  
  const initPwdInput = async (childData) => {
    initRobustPassword(childData);
  };
  useEffect(() => {
    const storedLang = localStorage.getItem("i18nextLng");
   
    if (lang !== storedLang) {
        i18n.changeLanguage(lang);
    }
  }, []);
  const handleChangePassword = (e) => {
    const password = e.target.value;
    if(password !== confirmPassword){
      setErrorConfirmPassword(t('passwordNotConfirmed'));
      setCheckConfirm(false);
    }else {
      setCheckConfirm(true);
      setErrorConfirmPassword('');
    }
    initValue({
      ...pwdInput,
      password: e.target.value,
    });
    setErrorPassword(null);
    let caps; let small; let num; let
      specialSymbol;
    if (password.length < 8) {
      setErrorPassword(
        t('pwdMsg1'));
    } else {
      caps = (password.match(/[A-Z]/g) || []).length;
      small = (password.match(/[a-z]/g) || []).length;
      num = (password.match(/[0-9]/g) || []).length;
      specialSymbol = (password.match(/\W/g) || []).length;
      if (caps < 1) {
        setErrorPassword(t('pwdMsg2'));
      } else if (small < 1) {
        setErrorPassword(t('pwdMsg3'));
      } else if (num < 1) {
        setErrorPassword(t('pwdMsg4'));
      } else if (specialSymbol < 1) {
        setErrorPassword(t('pwdMsg5'));
      }
    }
  };

  const handleChangeConfirmPassword = event => {
    setConfirmPassword(event.target.value);
    if (event.target.value === '' || event.target.value === null || event.target.value === 'undefined') {
      setErrorConfirmPassword(t('champConfirmPasswordObligatoire'));
      setCheckConfirm(false);
    } else if (event.target.value !== pwdInput.password) {
      setErrorConfirmPassword(t('passwordNotConfirmed'));
      setCheckConfirm(false);
    } else {
      setCheckConfirm(true);
      setErrorConfirmPassword('');
    }
  };


  const handleChangePass = event => {
    if (checkConfirm && isStrong === 'strong') {
      const data = {
        password: pwdInput.password,
        username
      };
      axios.post(BACKEND_URL + '/api/changeForgetPassword', data).then((response) => {
        setSuccessAdd(true);
        setTimeout(() => {
            setSuccessAdd(null);
            navigate("/", { replace: true }); 
        }, 1500);
      }).catch((error) => {
        setSuccessAdd(false);
        setTimeout(() => {
          setSuccessAdd(null);
        }, 1500);
      });
    } else {
      setSuccessAdd(false);
      setTimeout(() => {
        setSuccessAdd(null);
      }, 1500);
    }
  };
  
  return (
    <div className="col-md-6 offset-md-3">
      <div className="container forgetform">
        {/* <center>
          <img
            src="/images/Groupe 4774.png"
            className="card-img-top img-forget-pass"
            alt="..."
          />
        </center> */}
        <div className="card card-forget-pass ">
          <center>
            <img
              src={localStorage.getItem('i18nextLng')==="en"?"/images/newLogo.png":"/images/newLogo.png"}
              className="card-img-top logo-quess-pass"
              alt="..."
            />
          </center>
          
        <div className='container div-form-forget-pass'>
           {/*  <label className="label-forget-pass" htmlFor="password">{t('password')}</label> */}
            <input
              type="password"
              id="password"
              placeholder={t('password')}
             value={pwdInput.password}
              onChange={handleChangePassword}
              className="form-control input-forget-pass"
            />
            {errorPassword && <p style={{ color: 'red' }}>{t(errorPassword)}</p>}
            
            <div className='barPass'>
              <StrengthMeter password={pwdInput.password} actions={initPwdInput}  className="progressPass"/>
              </div>
           {/*  <label className="label-forget-pass" htmlFor="confirmPassword">{t('confirmationPassword')} </label> */}
            <input
              type="password"
              id="confirmPassword"
              placeholder={t('confirmationPassword')} 
              value={confirmPassword}
              onChange={handleChangeConfirmPassword}
              className="form-control input-forget-pass"
            />
            {errorConfirmPassword && <p style={{ color: 'red' }}>{t(errorConfirmPassword)}</p>}

            <button onClick={handleChangePass} className="btn btn-primary btn-forget-pass">{t('SAVE')}</button>
            {successAdd === true && (
                <div className="alert alert-success mt-3 mb-0">
                  {t("SuccessChangePassword")}
                </div>
              )}
              {successAdd === false && (
                <div className="alert alert-danger mt-3 mb-0">
                  {t("FailedUserChangePassword")}
                </div>
              )}
        </div>
        </div>
      </div>
    </div>
  );
}

export default Changeforgetpass;
