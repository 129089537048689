import React from 'react';
import '../CardBce/cardBce.css'; // Assurez-vous d'ajouter les styles nécessaires dans Card.css

const CardBce = ({ logo, imageApp, description,logoLink }) => {
  return (
    <div className=''>
    <div className="card">
      <img src={imageApp} alt="imageApp" className="logo"   /> 
      <div className="card-hover">
        <div className="card-description">{description}</div>
      </div>  
       
    </div>
     <div className="circle-image">
     <a href={logoLink} target="_blank" rel="noopener noreferrer">
      <img src={logo} alt="Logo" className="image-app"  style={{ borderRadius: '50%', alignItems: 'center' }}/>
      </a>
      </div> 
     </div>
  );
};

export default CardBce;