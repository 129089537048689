import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../config/const";
import i18n from "../../_i18n/i18n";
import { useTranslation } from "react-i18next";
import axios from "axios"; // Import Axios
import "../Nav/nav.css";
import { useNavigate } from "react-router-dom";
import Banner from "../Banner/Banner";
import Grid from '@mui/material/Grid';

function Nav() {
  const navigate = useNavigate();
  const [t] = useTranslation(["translation"]);
  const [trans, setTrans] = useState(localStorage.getItem("i18nextLng"));
  const token = sessionStorage.getItem("token");

  const [user, setUser] = useState({});
  const [urlBce, setUrlBce] = useState();
  useEffect(() => {
    if (token) {
      getUserInfo(); // Call function to get user info
    }
    if (localStorage.getItem("i18nextLng") === "en") {
      setUrlBce("https://bcepharma.com/en/");
    } else {
      setUrlBce("https://bcepharma.com");
    }
  }, [token]);

  const getUserInfo = async () => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      };
      const response = await axios.get(
        BACKEND_URL + "/api/getUserInfo",
        config
      );
      setUser(response.data.userInfo);
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  function changeLanguageOnClick(language) {
    console.log(language);
    if (language === "en") {
      setTrans("fr");
      language = "fr";
      setUrlBce("https://bcepharma.com");
    } else {
      setTrans("en");
      language = "en";
      setUrlBce("https://bcepharma.com/en/");
    }
    i18n.changeLanguage(language);
  }
  const logout = () => {
    sessionStorage.removeItem("token");
    navigate("/");
  };
  const toContactForm = () => {
    navigate("/contact");
  };

  if (!token) return null; // Return null if token is not present

  return (
    <>
      <nav className="navbar navbar-expand ">
      <Grid
              container
              alignItems="flex-start"
              justify="flex-start"
              direction="row"
            >
              <Grid item  xl={4} lg={4} md={4} sm={6} xs={6}>
                <div className="navbar-nav ">
                <NavLink to={urlBce} className="nav-item nav-link ">
                    <img
                    src={"/images/newLogo.png"}
                    className="card-img-top imgLogo"
                    alt="..."
                    />{" "}
                </NavLink>
                </div>
              </Grid>
              <Grid item xl={4} lg={4} md={4} ></Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
                <div className="navbar-nav divNav2">
                <div className="navbar-nav contactNavUs" onClick={toContactForm}>
                    <i class="fa-solid fa-message ">
                    <h5>{t("contactUs")}</h5>
                    </i>
                </div>

                <div className="dropdown nav-button">
                    <i
                    className="dropdown-toggle nav-text username"
                    data-bs-toggle="dropdown"
                    >
                    {user.FirstName} {user.LastName}{" "}
                    </i>
                    <div className="dropdown-menu">
                    {/* <button href="#" className="dropdown-item  profil-text"><i class="fa-solid fa-sliders icon-nav"></i>{t("MySettings")}</button> */}
                    <button
                        onClick={() =>
                        changeLanguageOnClick(localStorage.getItem("i18nextLng"))
                        }
                        className="dropdown-item  profil-text"
                    >
                        <i class="fa-solid fa-globe icon-nav"></i>
                        {t("Frensh/English")}
                    </button>
                    <button onClick={logout} className="dropdown-item  profil-text">
                        <i class="fa-solid fa-door-open icon-nav"></i>
                        {t("LogOut")}
                    </button>
                    </div>
                </div>
                </div>
              </Grid>
      </Grid>
      </nav>
      <Banner />
    </>
  );
}

export default Nav;
