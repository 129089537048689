import React, { useState , useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { BACKEND_URL } from "../../config/const";
import { useNavigate } from "react-router-dom";
import '../DoubleAuth/verif2fa.css'
const Verif2FA=()=> {
  const { t } = useTranslation();
  const [successAdd, setSuccessAdd] = useState(null);
  const [sendMail, setsendMail] = useState(null);
  const [verificationcode, setVerificationcode] = useState();
  const navigate = useNavigate();
 
  

  useEffect(() => {
   
  }, []);
 
  const resendMail = event => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    };
    const data2FA = {
      mode : 'mail',
      lang : localStorage.getItem('i18nextLng')
    };
    axios.post(BACKEND_URL + '/api/sendCodeBCE2FA', data2FA , config )
      .then(response => {
        setsendMail(true);
        setTimeout(() => {
          setsendMail(null);
        }, 1500);
      }).catch((error) => {
        setsendMail(false);
        setTimeout(() => {
          setsendMail(null);
        }, 1500);
      });
  }
  function handleChangeCode(event) {
    setVerificationcode(event.target.value);
  }
  const handleLogin = event => {
    setSuccessAdd(null);
    const config = {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      }
    };
    axios.post(BACKEND_URL + '/api/loginBCE2FA', 
      {userId: sessionStorage.getItem('idUser'),verificationcode : verificationcode },config )
      .then(response => {
        if(response.data.isVerified === true)
        {
          sessionStorage.setItem('isVerified',"true");
          axios.get(BACKEND_URL + '/api/saveLoginLog', config);
          axios.get(BACKEND_URL + '/api/getactifgroupdocspermission', config)
            .then(res => {
              setSuccessAdd(true);
            });
            setTimeout(() => {  
              setSuccessAdd(null);                        
              navigate('/home');                                            
          }, 1500);
          } else {
            setSuccessAdd(false);
        setTimeout(() => {
          setSuccessAdd(null);
        }, 1500);
          }
      }).catch((error) => {
        setSuccessAdd(false);
        setTimeout(() => {
          setSuccessAdd(null);
        }, 1500);
      });
  };
  return (
    <div className="col-md-6 offset-md-3">
      <div className="container verifyForm">
       {/*  <center>
          <img
            src="/images/Groupe 4774.png"
            className="card-img-top img-forget-pass2fa"
            alt="..."
          />
        </center> */}
        <div className="card card-forget-pass2fa ">
          <center>
            <img
              src={localStorage.getItem('i18nextLng')==="en"?"/images/newLogo.png":"/images/newLogo.png"}
              className="card-img-top logo-quess-pass2fa"
              alt="..."
            />
          </center>
          
        <div className='container div-form-forget-pass2fa'>
        <div className="card card-verif2fa">
            <label className="label-forget-pass2fa" htmlFor="verifCode">{t('textVerifCode')}</label>
            <input
              type="text"
              id="verifCode"
              placeholder={t('verifCode')}
              maxlength="6"
              onChange={handleChangeCode}
             
            />
            {successAdd === false && (
                <div className="alert alert-danger mt-3 mb-0">
                  {t("FailedLoginCode")}
                </div>
              )}
              {successAdd === true && (
                <div className="alert alert-success mt-3 mb-0">
                  {t("successLogin")}
                </div>
              )}
              {sendMail === false && (
                <div className="alert alert-danger mt-3 mb-0">
                  {t("errorEmailsent")}
                </div>
              )}
              {sendMail === true && (
                <div className="alert alert-success mt-3 mb-0">
                  {t("emailsent")}
                </div>
              )}
            
            
            <button  className="btn btn-primary btn-forget-pass2fa" onClick={handleLogin}>{t('login')}</button>
            <button  className="btn btn-primary btn-forget-pass2fa" onClick={resendMail}>{t('reset')}</button>
           
        </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Verif2FA;
