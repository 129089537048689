import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import ContactForm from './components/ContactForm/ContactForm';
import ForgetPass from './components/ForgetPass/Changeforgetpass';
import Verif2FA from './components/DoubleAuth/Verif2FA';
import './index.css';


const App = () => {
  const [token, setToken] = useState(sessionStorage.getItem('token'));
  

  return (
    <Router>
      <div className='main'> 
      <Routes>
        <Route path="/" element={<Login setToken={setToken} />} />
        <Route
          path="/home"
          element={token ? <Home /> : <Navigate to="/" />} 
        />
        <Route path="/contact" element={<ContactForm/>} />
        <Route exact path="/verif2FA"  element={<Verif2FA/>}/>
        <Route path="/forgetChangePass/:username/:lang" element={<ForgetPass />} />
      </Routes>
      </div>
    </Router>
  );
};

export default App;