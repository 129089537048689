import React from "react";
import { useTranslation } from 'react-i18next';
import "./StrengthMeter.css";
const StrengthMeter = (props) => {
  const { t } = useTranslation();
  const pwdValidate = props.password;
  const initPwdChecker = () => {
    let pwdCheck = 0;
    let validateRegex = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];
    validateRegex.forEach((regex, i) => {
      if (new RegExp(regex).test(pwdValidate)) {
        pwdCheck += 1;
      }
    });
    if(pwdValidate.length<8 && pwdCheck===0){
      return {
        strength: 0,
        val: "" ,
      };
    }
      switch (pwdCheck) {
        case 0:
          return {
            strength: 0,
            val: "",
          };
        case 1:
          return {
            strength: 1,
            val: "weak" ,
          };
        case 2:
          return {
            strength: 2,
            val: "fair",
          };
        case 3:
          return {
            strength: 3,
            val: "good",
          };
        case 4:
          return {
            strength: (pwdValidate.length>8?4:3),
            val: (pwdValidate.length>8?"strong":"good"),
          };
        default:
          return null;
      }
    
   
  };
  {
    props.actions(initPwdChecker().val);
  }
  return (
    <>
      <div className="wrapper">
        <progress
          className={`pwd-checker-bar strength-${initPwdChecker().val}`}
          value={initPwdChecker().strength}
          max="4"
        />
        <br />
        <p className="pwd-label">
          {props.password && (
            <div>
              <p className={`label strength-${initPwdChecker().val}`}>
              
                <strong>{t(initPwdChecker().val)} </strong>
              </p>
            </div>
          )}
        </p>
      </div>
    </>
  );
};
export default StrengthMeter;