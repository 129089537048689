import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../config/const";
import "../Login/login.css";
import { useNavigate } from "react-router-dom";
import i18n from "../../_i18n/i18n";
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
const Login = ({ setToken }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState(null);
  const [authSuccess, setAuthSuccess] = useState(null);
  const [successForget, setSuccessForget] = useState(null);
  const [urlBce, setUrlBce] = useState();

  const [t] = useTranslation(["translation"]);
  const [trans, setTrans] = useState("en");
  useEffect(() => {
    sessionStorage.clear();
    if(localStorage.getItem('i18nextLng')==="en"){
        setUrlBce("https://bcepharma.com/en/")
    }else{
        setUrlBce("https://bcepharma.com")   
    }
}, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(BACKEND_URL + "/api/login", {
        username,
        password,
      });
      const token = response.data.token;
      setAuthSuccess(true);
      setTimeout(() => {
        
        sessionStorage.setItem("token", token);
        setToken(token);
        const config = {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token')
          }
        };
        const data = {
          username: username,
          mode: 'mail',
          lang: localStorage.getItem('i18nextLng')
        };
        axios.get(BACKEND_URL + '/api/getUserInfo', config)
        .then(response => {
          sessionStorage.setItem('idUser', response.data.userInfo.Id);
        })
        axios.get(BACKEND_URL + '/api/has2FA', config)
          .then(resp => {
            if (resp.data.hasDoubleAuth === true) {
              axios.post(BACKEND_URL + '/api/sendCodeBCE2FA', data, config)
                .then(res => {
                  navigate('/verif2FA');
                });
            } else {              
                  navigate('/home');                
            }})
      }, 1500);
    } catch (error) {
      setAuthError(true);
      setTimeout(() => {
        setAuthError(null);
      }, 1500);
    }
  };


 



  function changeLanguageOnClick(language) {
    if (language === "en") {
      setTrans("fr");
      language = "fr";
      setUrlBce("https://bcepharma.com")
    } else {
      setTrans("en");
      language = "en";
      setUrlBce("https://bcepharma.com/en/")
    }
    i18n.changeLanguage(language);
  }
  const handleForgetPassword = (event) => {
    axios
      .post(BACKEND_URL + "/api/forgetPasswordBce", {
        username: username,
        lang: localStorage.getItem('i18nextLng'),
      })
      .then((response) => {
        if (response.data.message) {
          setSuccessForget(true);
          setTimeout(() => {
            setSuccessForget(null);
              window.location.reload();
          }, 1500);
        } else {
          setSuccessForget(false);
          setTimeout(() => {
            setSuccessForget(null);
              window.location.reload();
          }, 1500);
        }
      })
      .catch((error) => {
        setSuccessForget(false);
        setTimeout(() => {
          setSuccessForget(null);
            window.location.reload();
        }, 1500);
      });
  };
  const toContactForm = () => {
    navigate("/contact");
  };
  return (
    <div className="col-md-6 offset-md-3">
      <div className="container login-form">
        {/* <center>
          <img
            src="/images/Groupe 4774.png"
            className="card-img-top img-login"
            alt="..."
          />
        </center> */}
        <div className="card1 card-login">
          <center>
            
            <NavLink  to={urlBce} className="nav-item nav-link">
            <img
              src={localStorage.getItem('i18nextLng')==="en"?"/images/newLogo.png":"/images/newLogo.png"}
              className="card-img-top logo-quess"
              alt="..."
            />
            </NavLink>
          </center>
          <div className="card-body">

            <form onSubmit={handleSubmit} className="div-form">
              <div className="form-group">
                {/* <label className="label-login">{t("username")}</label> */}
                <input
                  type="text"
                  placeholder={t("username")}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="form-control "
                />



              </div>
              <div className="form-group">
               {/*  <label className="label-login">{t("password")}</label> */}
                <input
                  type="password"
                  placeholder={t("password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control "
                />
              </div>

              <button type="submit" className="btn btn-primary btn-login">
                {t("login")}
              </button>
              {authSuccess === true && (
                <div className="alert alert-success mt-3 mb-0">
                  {t("successLogin")}
                </div>
              )}
              {authError === true && (
                <div className="alert alert-danger mt-3 mb-0">
                  {t("FailedLogin")}
                </div>
              )}
              {successForget === false && (
                <div className="alert alert-danger mt-3 mb-0">
                  {t("failedForgetPass")}
                </div>
              )}
              {successForget === true && (
                <div className="alert alert-success mt-3 mb-0">
                  {t("successForgetPass")}
                </div>
              )}
            </form>
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div class="col-auto">
                  <button
                    onClick={() => changeLanguageOnClick(trans)}
                    className="dropdown-item  profil-text custom-button"
                  ><center> <i className="fa-solid fa-globe icon-nav custom-icon"></i></center>
                   
                   <h6> {t("Frensh/English")} </h6>
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    className="btn btn-secondary"
                    onClick={handleForgetPassword}
                  >
                    {t("forgetPassword")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contactUs">
        <i class="fa-solid fa-message " onClick={toContactForm}>
          <h5>{t("contactUs")}</h5>
        </i>
      </div>
    </div>
  );
};

export default Login;
