import 'rc-banner-anim/assets/index.css';
import React, { useEffect, useState } from 'react';
import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import { useTranslation } from "react-i18next";
import "../Banner/banner.css";
import MovingText from 'react-moving-text';
import Grid from '@mui/material/Grid';

const BgElement = Element.BgElement;

const Banner = () => {
    const [t] = useTranslation(["translation"]);
  const [bannerAnimState, setBannerAnimState] = useState({
    currentKey: '0',
  });

  useEffect(() => {
    const interval = setInterval(() => {
      // Change the key to switch between elements
      setBannerAnimState((prevState) => ({
        currentKey: prevState.currentKey === '0',
      }));
    }, 3000); 

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div>
    <Grid
              container
              alignItems="flex-start"
              justify="flex-start"
              direction="row"
            >
              <Grid item  xl={2} lg={2} md={2} sm={2} xs={2}></Grid>
              <Grid item  xl={8} lg={8} md={8} sm={8} xs={8}>
              
              <BannerAnim prefixCls="banner-user" type="across" duration={500} arrow={true} autoPlay >
                <Element prefixCls="banner-user-elem" key="0"  className='BannerDiv'>
                  <BgElement key="bg" className="bg" style={{ backgroundImage: `url('../../../public/images/bar.png')`}}  />
                  <TweenOne className="banner-user-title BannerDiv" animation={{ y: 30, type: 'from' }}>
                  
                  <MovingText
                    type="flash"
                    duration="4000ms"
                    delay="0s"
                    direction="normal"
                    timing="linear"
                    iteration="infinite"
                    fillMode="none"
                    className="news"
                    >
                  {t('news')}
                  </MovingText>
                  </TweenOne>
                  <TweenOne
                    className="banner-user-text BannerDiv"
                    animation={{ y: 30, type: 'from', delay: 100,opacity:'1' }}
                  >
                  <center> {t('new1.0')} <strong><a href="/contact" style={{ color: '#fff' }}>{t('new2.1')}</a></strong>{t('new1.2')}</center>
                  </TweenOne>
                </Element>
                <Element prefixCls="banner-user-elem" key="1">
                  <BgElement key="bg" className="bg" style={{ backgroundImage: `url('../../../public/images/bar.png')`}} />
                  <TweenOne className="banner-user-title  BannerDiv" animation={{ y: 30, type: 'from' }}>
                  <MovingText
                    type="flash"
                    duration="4000ms"
                    delay="0s"
                    direction="normal"
                    timing="linear"
                    iteration="infinite"
                    fillMode="none"
                    className="news"
                    >
                  {t('news')}
                  </MovingText>
                  </TweenOne>
                  <TweenOne
                    className="banner-user-text BannerDiv"
                    animation={{ y: 30, type: 'from', delay: 100, opacity:'1'}}
                  >
                      <center>{t('new2.0')} <strong><a href="/contact"  style={{ color: '#fff' }}>{t('new2.1')}</a></strong>{t('new2.2')}</center>
                  </TweenOne>
                </Element>
                
              </BannerAnim>
              </Grid>
              <Grid item  xl={2} lg={2} md={2} sm={2} xs={2}></Grid>
    </Grid>
    </div>
  );
};

export default Banner;