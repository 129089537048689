import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Card from '../Card/Card';
import CardBce from '../CardBce/CardBce';
import Nav from '../Nav/Nav';
import Banner from '../Banner/Banner'; 
import { BACKEND_URL } from '../../config/const';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import "../Home/home.css"
import Grid from '@mui/material/Grid';

function Home() {
    const [urlLogs, setUrlLogs] = useState('');
    const [t] = useTranslation(["translation"]);
    const navigate = useNavigate();
    const token=sessionStorage.getItem('token');
    const lang=localStorage.getItem('i18nextLng')
    const urlDoc = "https://filemanager.bcepharma.com/app/reRoute/" +token+"/"+lang
    const urlLmsFR = "https://lms.bcepharma.com/?locale=fr"
    const urlLmsEN = "https://lms.bcepharma.com/?locale=en"
    const urlEval ="https://quess-app.bcepharma.com/"
    useEffect(() => {
      const token = sessionStorage.getItem('token');
      if (!token) {
        navigate('/');
      } else {
        // Si le token est présent, vous pouvez effectuer d'autres actions ici
        getUserCheo();
      }
    }, [navigate]);

const getUserCheo = async () => {

  try {
    const config = {
      headers: {
        Authorization: 'Bearer '+ sessionStorage.getItem("token")
      }
    }
    const response = await axios.get(BACKEND_URL+'/api/userCheo', config);
        if(response.data.isCheo){
          setUrlLogs("https://quess360cheo.bcepharma.com")
            
        }else{
          setUrlLogs("https://quess360.bcepharma.com")

        }
          
      } catch (error) {
          
          console.error('Error fetching user info:', error);
      }
      };
      
    return (
      <div >
        

      <Nav />
     
      <Grid
              container
              alignItems="flex-start"
              justify="flex-start"
              direction="row"
            >
              <Grid item  xl={2} lg={2} md={2} sm={2} xs={2}></Grid>
              <Grid item  xl={8} lg={8} md={8} sm={8} xs={8}>
                <div className="container div-home">
                    <div className="row">
                    <Grid
                      container
                      alignItems="flex-start"
                      justify="flex-start"
                      direction="row"
                      spacing={3}
                    >
                            <Grid item  xl={3} lg={3} md={3} sm={6} xs={12}   >
                            <CardBce
                                logo={"../images/bouton quess LMS.svg"}
                              imageApp={localStorage.getItem('i18nextLng')==="en"?"../images/QuessLMS.png":"../images/QuesSGA.png"}
                              description={t("Learning management system used for training personnel")}
                              logoLink={localStorage.getItem('i18nextLng')==="en"?urlLmsEN:urlLmsFR}
                              
                              />
                            </Grid>
                            <Grid item  xl={3} lg={3} md={3} sm={6} xs={12}  >
                            <CardBce
                                logo={"../images/bouton quess logs.svg"}
                                imageApp={localStorage.getItem('i18nextLng')==="en"?"../images/QuessLogs.png":"../images/QuessRegistres.png"}
                                description={t("Record keeping for periodic monitoring of activities and parameters related to the compounding sterile preparations sector.")}
                                logoLink={localStorage.getItem('i18nextLng')==="en"?urlLogs+"/?lang=en":urlLogs+"/?lang=fr"}
                             />
                            </Grid>
                            <Grid item  xl={3} lg={3} md={3} sm={6} xs={12}   >
                            <CardBce
                                logo={"../images/bouton quess docs.svg"}
                              imageApp={localStorage.getItem('i18nextLng')==="en"?"../images/QuessDocsEN.png":"../images/QuessDocsFR.png"}
                              description={t("A file manager system for your documents and P&P.")}
                              logoLink={urlDoc}
                            />
                            </Grid>
                            <Grid item  xl={3} lg={3} md={3} sm={6} xs={12}   >
                            <CardBce
                                logo={"../images/bouton quess assessment.svg"}
                              imageApp={localStorage.getItem('i18nextLng')==="en"?"../images/QUESS-ASSSESSMENT.png":"../images/QUESS_EVALUATION.png"}
                                description={t("Skills assessment system.")}
                                logoLink={urlEval}
                            />
                            </Grid>
                        </Grid>
                    </div>
                    
                </div>
              </Grid>
              <Grid item  xl={2} lg={2} md={2} sm={2} xs={2}></Grid>

      </Grid>
        </div>
    );
}

export default Home;